@import 'static/css/global/global.scss';

.product-card {
    outline: 0;
    box-shadow: none;
    margin-bottom: 6px;
    max-width: 360px;
    cursor: pointer;
    border-radius: 12px;
    @media screen and (max-width:1023px) {
        padding: 6px;
    }

    .product-card-wrapper {
        background: #fff;
        border-radius: 1rem;
        padding: 0;
        border: 1.5px solid #c3c3c3;
        border-radius: 10px;
        position: relative;

        @media screen and (max-width:1023px) {
            width: 100%;
        }

        .product-image-container {
            width: 100% !important;
            margin-bottom: 0rem;

            .orange_background {
                background: #EDEDED;
                border-radius: 10px;
            }

            a {
                display: block;
                width: 100% !important;
                height: 100%;
                object-fit: contain !important;
                outline: 0;
                box-shadow: none;

                .card-image {
                    display: block;
                    width: 100%;
                    mix-blend-mode: darken;
                    height: 100%;
                    border-radius: 10px;

                    @media screen and (max-width:1023px) {
                        max-height: 204px;
                    }

                }

                .sca-fg-img-collect {
                    display: block !important;
                    object-fit: contain !important;
                    position: absolute;
                    top: 4px !important;
                    right: 4px;
                    height: 24px !important;
                    width: auto;
                    outline: 0;
                    box-shadow: none;
                }
                @media screen and (max-width:1023px) {
                    .width-50 {
                        top: 0px !important;
                        left: -4px !important;
                    }
                }
                .gender-img{
                    right: 4px;
                    left: auto !important ;
                    top: 4px !important;
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    height: 24px;
                    width: auto;
                    outline: none;
                }
            }
        }

        .product-detail {
            padding: 20px 14px 30px;
            border-top: 1px solid #fff;
            padding: 10px 14px 30px;

            @media screen and (max-width:1023px) {
                padding: 10px;

            }

            .loyalty-not-avail{
                min-height: 42px;
            }

            .star-rating {
                display: flex;
                align-items: center;
                padding: 0 0 0 15px;
                min-height: 18px;

                @media screen and (max-width:1023px) {
                    padding: 0px;
                    min-height: 18px;
                }


                .star {
                    padding-right: 10px;

                    @media screen and (max-width:1023px) {
                        padding-right: 6px;
                    }
                }

                .rating-brd {
                    border-left: 2px solid #381284;
                    height: 12px;
                    width: 2px;
                    padding: 0px 0px 0px 10px;

                    @media screen and (max-width:1023px) {
                        padding: 0px 0px 0px 6px;
                    }
                }

                .left-content {
                    display: flex;

                    span {
                        font-size: 14px;
                        font-style: normal;
                        font-family: $font-family-barlow;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 0;
                        color: #371280;

                        @media screen and (max-width:1023px) {
                            font-size: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            min-height: 11px;
                        }
                    }
                }

            }

            .product-name {
                a {
                    color: #2d2d2e;
                    font-family: $fontFamily-default;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    min-height: auto;
                    text-align: left;
                    margin-bottom: 10px;
                    max-width: 100%;
                    overflow: hidden;
                    height: 47px !important;
                    text-overflow: ellipsis;
                    margin-bottom: 12px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;

                    @media screen and (max-width:1023px) {
                        margin-top: 5px;
                        max-width: 100%;
                        overflow: hidden;
                        height: 54px !important;
                        text-overflow: ellipsis;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        font-size: 14px;
                        height: 36px !important;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden
                    }
                }

                color : #002140;
                font-family : $fontFamily-default;
                font-size : 18px;
                font-style : normal;
                font-weight : 500;
                line-height : normal;
                min-height : auto;
                text-align : left;
                margin-bottom: 10px;

                @media screen and (max-width:1023px) {
                    margin-bottom: 5px;
                }

            }

            .tag_flavour {
                display: flex;

                p.product_tag {
                    color: #272b65;
                    font-family: $fontFamily-default;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0;
                    max-width: 225px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                @media screen and (max-width:1023px) {
                    padding: 8px 0px 2px 0px;

                    p.product_tag {
                        font-size: 12px;
                        max-width: 130px;
                    }
                }
            }

            .no-flavours {
                min-height: 27px;
            }

            .form-container {
                .product-card-footer {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-top: 1.6rem;
                    border-top: 1px solid #d9dadb;
                    padding-top: 0;
                    border-top: 0;
                    margin-bottom: 6px;

                    &.ctas-home {
                        margin-top: 16px;
                        flex-direction: row;
                        align-items: flex-end;
                    }

                    .ctas {
                        display: flex;
                        flex-direction: row;
                        gap: 12px;
                        width: 100%;
                        justify-content: space-between;
                        margin: 20px 0 0 0;

                        &.homepage {
                            display: block;
                            padding: 0 24px;
                            .atc-custom {
                                button {
                                    color: #FFF;
                                    background: #292c66;
                                }
                            }
                            @media screen and (max-width:1024px) {
                                display: flex;
                                flex-direction: column;
                                padding: 0;
                            }
                        }

                        @media screen and (max-width:1024px) {
                            flex-direction: column-reverse;
                            margin: 0;
                            margin-top: 8px;
                            gap: 6px;

                            button {
                                width: 100%;
                                height: 32px;
                            }
                        }

                        &.mweb {
                            margin: 0;
                            margin-top: 8px;
                            gap: 6px;
                            flex-direction: column-reverse;

                            button {
                                width: 100%;
                                height: 32px;
                            }
                        }

                        .atc-custom {
                            button {
                                background: #FFF;
                                color: #292c66;
                            }
                        }
                    }

                    button {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 28px;
                        width: 100%;
                        color: #fff;
                        border: 0;
                        outline: 0;
                        box-shadow: none;
                        transition: .8s;
                        border-radius: 24px;
                        background: #292c66;
                        border: 1px solid #292c66;
                    }

                    .price-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        align-items: flex-start;

                        .product_price_details {
                            display: flex;
                            align-items: baseline;
                            margin-bottom: 12px;
                            margin-top: 6px;
                            min-height: 28px;

                            @media screen and (max-width:1023px) {
                                min-height: 13px;
                            }

                            .product-sale-price {
                                margin-right: 5px;
                                text-decoration: line-through;
                                color: #4c5c67;
                                font-family: $fontFamily-default;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }

                            .product-offer {
                                color: green;
                                font-family: $fontFamily-default;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }

                        .buy_product {
                            border: red 1px solid;
                        }

                        .price_select {
                            display: flex;
                            align-items: center;

                            .slash {
                                position: relative;
                                right: 7%;
                                font-size: 32px;
                                @media screen and (max-width: 1023px) {
                                    font-size: 24px;
                                }
                            }

                            .product-price {
                                font-size: 32px;
                                margin-right: 24px;
                                color: #272b65;
                                font-weight: 600;

                                @media screen and (max-width: 1023px) {
                                    font-size: 18px;
                                }

                            }

                            .select-prod-container {
                                min-height: auto;
                                margin-bottom: 0;

                                .select-option {
                                    width: 65px;
                                    height: 35px;
                                    font-size: 18px;
                                    border: 2px solid #e8e8e8;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    appearance: none;
                                    background-color: #e8e8e8;
                                    text-align: center;
                                    border-radius: 6px;
                                    font-weight: 600;

                                    @media screen and (max-width : 767px) {
                                        width: 60px;
                                        height: 28px;
                                        font-size: 16px;
                                        font-weight: 500;
                                    }
                                }

                                #form-control {
                                    display: block;
                                    width: 100%;
                                    height: calc(1.5em + 0.75rem + 2px);
                                    padding: 0.375rem 0.75rem;
                                    font-size: 1rem;
                                    font-weight: 400;
                                    line-height: 1.5;
                                    color: #495057;
                                    background-color: #fff;
                                    background-clip: padding-box;
                                    border: 1px solid #ced4da;
                                    border-radius: 0.25rem;
                                    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                                }
                            }
                        }
                    }

                    .loyalty-not-avail{
                        min-height: 42px;
                    }
                }

                button {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    color: #fff;
                    padding: 0.5rem 1.1rem;
                    border: 0;
                    outline: 0;
                    box-shadow: none;
                    transition: .8s;
                    border-radius: 7.154px;
                    background: #292c66;
                    border: 1px solid #292c66;
                    min-width: 134px;

                    @media screen and (max-width:1023px) {
                        float: right;
                        min-width: 50px;
                        padding: 0px !important;
                    }
                }

                @media screen and (max-width:1023px) {
                    .product-card-footer {
                        display: block;

                        &.mweb {
                            margin: 0;
                            margin-top: 8px;
                            gap: 6px;
                            flex-direction: column-reverse !important;

                            button {
                                width: 100%;
                            }
                        }

                        button {
                            font-size: 14px;
                            line-height: 14px;
                            border-radius: 24px;
                            font-weight: 500;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }
        }

        .lightOrange {
            background: #feeadc;
        }


    }
}

.col-xl-auto {
    // position: relative;
    width: 100%;

    @media screen and (max-width:1023px) {
        margin-top: 40px;
    }

}

.width-50 {
    @media screen and (max-width:1023px) {
        width: 50%;
        margin-top: 0px !important;
        padding-right: 2px;

        &.home {
            width: 100%;
        }
    }

}

.width-100 {
    @media screen and (max-width:1023px) {
        width: 100%;
        margin-top: 0px !important;
    }

}