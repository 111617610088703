@import '../../../static/css/global/home_global.scss' ;

.buyAgain {
    width: 100%;
    border-radius: 4px;
    padding-bottom: 55px;
    margin: 45px 0;
    position: relative;
    &::before{
        position: absolute;
        left: 50%;
        margin-left: -50vw;
        width: 100vw;
        height: 100%;
        content: '';
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/reorder/offer.svg) no-repeat right top, linear-gradient(0deg, #FFECEE .67%, #fff 5%, #FFF .44%, #FFECEE 100.67%);
    }
    @media only screen and (max-width:1024px) {
        padding-bottom: 45px;
        margin: 20px 0;
    }
    .heading {
        color: #1E1838;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        padding-top: 32px;
        position: relative;

        @media only screen and (max-width:1024px) {
            font-size: 24px;
            padding: 29px 0 0 9px
        }
    }

    .sub-heading {
        color: #292C66;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin: 9px 0 20px 0;
        position: relative;

        @media only screen and (max-width:1024px) {
            font-size: 16px;
            margin: 9px 0 0 9px;
            line-height: 24px;

        }
    }
    .buyAgain-products {
        margin-top: 20px;
    }
}